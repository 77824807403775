export default {
    projects: 'Test It',
    resource: 'Project',

    id: 'ID',
    name: 'Project name',
    type: 'Type',
    delete: 'Delete',

    slug: 'Slug',
    status: 'Status',
    description: 'Description',
    search_text: 'Search text',
    landing_text: 'Landing text',
    rules_url: 'Rules URL',

    sign_up_start_date: 'Sign up start date',
    sign_up_end_date: 'Sign up end date',
    testing_start_date: 'Testing start date',
    testing_end_date: 'Testing end date',

    users: 'Signed up users',

    product: 'Product name',
    image: 'Image',

    // Types of projects
    'type_key.bigger': 'Bigger',
    'type_key.smaller': 'Smaller',
    'type_key.give-away': 'Give away',

    // Sections
    information: 'Project information',
    advanced: 'Advanced settings',

    // Labels
    name_field_description:
        'It is only necessary if the product name is not filled in, otherwise the title is automatically the product name.',
    product_field_description:
        'Either the product name or the title must be filled in.',
    image_field_description:
        'Product image is used automatically if no image is uploaded.',
    search_text_field_description:
        'This is the standard: Sign up as a tester and be one of the lucky 10!',
    landing_text_field_description:
        'A bit longer, but still a short description for the main page highlight',

    // Advanced Settings

    // Users List
    username: 'Username',
    email: 'Email',
    'skin_type.name': 'Skin type',
    'skin_concern.name': 'Skin concern',
    'hair_problem.name': 'Hair problem',
    birth_year: 'Age',
    confirmed: 'Confirmed',

    export: 'Export as CSV',
    bulk_actions: 'Bulk action ({{count}} users)',
    action_confirm_users_button: 'Confirm selected users',
    action_revoke_users_button: 'Unconfirm selected users',
    action_delete_users_button: 'Remove users from project',

    full_name: 'Full name',
    address: 'Address',
    contact_email: 'Contact e-mail',
    address_customized: '{{street}}, {{zip_code}} {{city}}',
};
