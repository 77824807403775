import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../Framework/Components/Buttons/Button';
import style from '../../../scss/components/buttons/button.module.scss';
import { MessageContext } from '../../Framework/Context/MessageContext';
import Modal from '../../Framework/Components/Modal';

const ChangeReviewTypeButton = ({ service, entity }) => {
    const [type, setType] = useState(entity.type);

    const { t } = useTranslation();
    const [marked, setMarked] = useState(false);
    const clearMark = () => setMarked(false);
    const { pushMessage } = useContext(MessageContext);

    const markReviewForChange = () => setMarked(true);

    const typeToChange = type === 'product' ? 'test-it' : 'product';

    console.log(entity, 'entityi here');
    const changeReviewType = () => {
        service
            .post('reviews', 'change-type', {
                reviews: { id: entity.id },
                type: typeToChange,
            })
            .then(() => {
                pushMessage({
                    title: t('application.change_review_success'),
                });
                setType(typeToChange);
            })
            .catch(() => {
                pushMessage({ title: t('application.change_review_error') });
            })
            .finally(() => {
                clearMark();
            });
    };

    return (
        <>
            <div>
                <p>Review Type</p>
                <p>{type}</p>
            </div>
            <Button
                classNames={`${style.button} ${style.basic} mt-4`}
                name={
                    type === 'test-it'
                        ? 'change_review_type_to_product'
                        : 'change_review_type_to_test_it'
                }
                onClick={markReviewForChange}
            />

            <Modal
                contentLabel={t(`application.confirm_selection`)}
                isOpen={!!marked}
                onRequestClose={clearMark}
            >
                <p>
                    {t('application.change_review_type_to', {
                        type: t(`application.${typeToChange}`),
                    })}
                </p>
                <Button
                    name="change_type_confirm_button"
                    click={changeReviewType}
                />
            </Modal>
        </>
    );
};

export default ChangeReviewTypeButton;

ChangeReviewTypeButton.propTypes = {
    /**
     * Type of service
     */
    service: PropTypes.object.isRequired,
    /**
     * Type of entity
     */
    entity: PropTypes.object.isRequired,
};
