import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import Boolean from '../../Framework/Components/Boolean';
import DeleteEntityButton from '../../Framework/Components/DeleteEntityButton';
import Age from '../../Framework/Components/TestIt/Age';
import UsersActions from '../../Framework/Components/TestIt/UsersActions';
import Text from '../../Framework/Components/Text';
import { MessageContext } from '../../Framework/Context/MessageContext';
import Sublist from '../../Framework/Pages/List/Sublist';

const TestItAdvanced = ({ service, resource, entity: projectEntity }) => {
    const [selected, setSelected] = useState([]);
    const [users, setUsers] = useState([]);
    const [signal, setSignal] = useState(false);
    const { pushMessage } = useContext(MessageContext);

    const handleSelectedChange = (selected) => setSelected(selected);
    const callSignal = () => setSignal(!signal);

    useEffect(() => {
        const fetchUsers = () => {
            service
                .get(`${resource}/${projectEntity.id}`)
                .then((data) => {
                    setUsers(data.data.users);
                })
                .catch(() => {
                    pushMessage({
                        title: 'application.fetch_error',
                        type: 'error',
                    });
                });
        };

        fetchUsers();
    }, [signal]);

    const actionsBar =
        users.length > 0 ? (
            <UsersActions
                service={service}
                resource={resource}
                entity={projectEntity}
                selectedUsers={selected}
                callSignal={callSignal}
            />
        ) : null;

    return (
        <div className="flex flex-col gap-4 mb-32">
            {actionsBar}
            <Sublist
                service={service}
                resource={resource}
                entity={users}
                selectable={users.length > 0}
                onSelectedChange={handleSelectedChange}
            >
                <Text name="id" />
                <Text name="username" />
                <Text name="email" />
                <Text name="contact_email" />
                <Boolean name="confirmed" />
                <Age name="birth_year" />
                <Text name="skin_type.name" isNested />
                <Text name="skin_concern.name" isNested />
                <Text name="hair_problem.name" isNested />
            </Sublist>
            {actionsBar}

            <DeleteEntityButton
                service={service}
                entity={projectEntity}
                resource={resource}
            />
        </div>
    );
};

export default TestItAdvanced;

TestItAdvanced.propTypes = {
    /**
     * Type of service
     */
    service: PropTypes.object.isRequired,
    /**
     * Type of resource
     */
    resource: PropTypes.string.isRequired,
    /**
     * Type of entity
     */
    entity: PropTypes.object.isRequired,
};
