export default {
    changed: 'The element you edited has changed, discard the changes?',
    time: 'Time',
    edit: 'Edit {{resource}}',
    add: 'Add',
    list: '{{resource}} list',
    new: 'New {{resource}}',
    delete: 'Delete',
    'Resource cannot be deleted due to existence of related resources.':
        '{{resource}} cannot be deleted because it has dependencies that prevent this.',
    save: 'Save',
    approve: 'Acceptance',
    reject: 'Rejection',
    upload: 'Upload',
    filter: 'Filter',

    approved: 'Approved',
    rejected: 'Rejected',
    approvalFailed: 'The approval failed!',
    saveFailed: 'Save failed!',
    rejectionFailed: 'Rejection failed!',

    'image-upload': 'Image upload',
    reset: 'Reset',
    image: 'Image',
    noImage: 'You have not uploaded an image yet!',

    crop: 'Edit',
    cut: 'Cut',
    cancel: 'Cancel',

    back: 'Back',
    passLock: 'I accept',
    noResults: 'No results',
    search: 'Search...',

    users: 'user',
    articles: 'article',
    brands: 'brand',
    categories: 'category',
    tags: 'label',
    authors: 'author',
    products: 'product',
    ingredients: 'ingredient',
    'categories-ingredient': 'ingredient function',
    'categories-product': 'product category',
    'categories-article': 'article category',
    'categories-skintype': 'skintype',
    'categories-skinconcern': 'skin concern',
    'categories-hairproblem': 'hair problem',

    home: 'Home page',
    logout: 'Logout',

    // User Edit Form
    delete_all_reviews: 'Delete all reviews',
    delete_all_reviews_confirm:
        'Are you sure you want to delete all reviews? Total of {{reviewsCount}} reviews will be deleted.',
    delete_all_reviews_button: 'Delete',

    // Transfer Reviews Form
    confirm_selection: 'Confirm selection',
    transfer_description:
        "You're about to transfer this review to product: {{product}}",
    transfer_confirm_button: 'Confirm',
    select: 'Select',
    copy_to: 'Copy',
    transfer_success: 'Review has been transferred successfully!',
    transfer_error: 'Review transfer failed!',

    // transfer product_offer
    transfer_product_offer:
        "You're about to transfer this product offer to product: {{product}}",
    move_confirm_button: 'Move',
    copy_confirm_button: 'Copy',

    // Orderable AutoComplete
    order_reverse: 'Reverse order',

    // Test It Project
    confirm_delete_title: 'Confirm deletion',
    delete_projects: 'Delete project',
    delete_projects_confirm: 'Are you sure you want to delete this project?',
    delete_error: 'Delete failed!',
    delete_success: 'Successfully deleted!',
    export_success: 'Successfully exported!',
    export_error: 'Export failed!',
    users_fetch_error: 'Failed to fetch users!',
    loading: 'Loading...',
    users_confirm_success: 'Successfully confirmed users!',
    users_confirm_error: 'Confirmation failed!',
    users_unconfirm_success: 'Successfully unconfirmed users!',
    users_unconfirm_error: 'Unconfirmation failed!',
    users_delete_success: 'Successfully deleted users!',
    users_delete_error: 'Delete failed!',

    // Reports
    delete_reports: 'Delete report',
    delete_reports_confirm: 'Are you sure you want to delete this report?',

    // Comments
    delete_comments: 'Delete comment',
    delete_comments_confirm: 'Are you sure you want to delete this comment?',
    'delete_product-offer-comments': 'Delete comment',
    'delete_product-offer-comments_confirm':
        'Are you sure you want to delete this comment?',

    // Ban User
    ban_user: 'Ban user for one week',
    ban_user_confirm: 'Are you sure you want to ban this user for one week?',
    ban_user_confirm_button: 'Ban',
    ban_success: 'User has been banned successfully until {{until}}!',
    ban_error: 'User ban failed!',

    // change review type
    change_type_confirm_button: 'Change',
    'test-it': 'Test-it review',
    product: 'Product review',
    change_review_type_to_product: "Change the review type to 'product'",
    change_review_type_to_test_it: "Change review type to 'Test-it'",
    change_review_type_to: 'Change review type to {{type}}',
    change_review_success: 'Review type changed successfully!',
    change_review_error: 'Review type change failed!',
};
