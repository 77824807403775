import React from 'react';

import { useTranslation } from 'react-i18next';
import StaticDropDown from '../../Framework/Components/Form/StaticDropDown';
import Text from '../../Framework/Components/Form/TextInput';
import Toggle from '../../Framework/Components/Form/Toggle';
import Search from '../../Framework/Components/Search';
import DateTime from '../../Framework/Components/Form/DateTime';
import CategoryInput from '../../Framework/Components/Form/CategoryInput';

const ProductSearch = (props) => {
    const { t } = useTranslation();
    const sortOptions = [
        {
            id: '-reviews_average',
            label: t('products.sortby.reviews_average_high'),
        },
        {
            id: 'reviews_average',
            label: t('products.sortby.reviews_average_low'),
        },
        {
            id: '-reviews_count',
            label: t('products.sortby.reviews_most'),
        },
        {
            id: 'reviews_count',
            label: t('products.sortby.reviews_least'),
        },
        {
            id: '-published_at',
            label: t('products.sortby.published_date_newest'),
        },
        {
            id: 'published_at',
            label: t('products.sortby.published_date_oldest'),
        },
        {
            id: '-popularity',
            label: t('products.sortby.popularity_highest'),
        },
        {
            id: 'popularity',
            label: t('products.sortby.popularity_lowest'),
        },
    ];

    return (
        <Search {...props}>
            <Text name="name" />
            <CategoryInput
                name="product_categories"
                type="product"
                minLength={2}
            />

            <div className="flex gap-8 items-center my-6">
                <DateTime name="published_from" />
                <DateTime name="published_to" />
            </div>
            <div className="flex gap-8 items-center my-6">
                <DateTime name="reviews_from" />
                <DateTime name="reviews_to" />
            </div>
            <Toggle name="is_hot" />
            <StaticDropDown name="sort_by" staticOptions={sortOptions} />
        </Search>
    );
};

export default ProductSearch;
