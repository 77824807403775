import React from 'react';
import PropTypes from 'prop-types';
import style from '../../../scss/components/form.module.scss';
import buttonStyle from '../../../scss/components/buttons/button.module.scss';

const GoToProductChangeRequestButton = ({ entity }) => {
    if (!entity) return null;

    const productChangeId = entity?.product_change_request_id;
    const productId = entity?.productId;

    if (!productChangeId && !productId) return null;

    const url = productChangeId
        ? `/product-change-requests/${productChangeId}/show`
        : `/products/${productId}/show`;

    const text = productChangeId
        ? 'Go to Product Change Request'
        : 'Go to Product';

    return (
        <div className={style.formGroup} style={{ marginTop: '2rem' }}>
            <a
                href={url}
                target="_blank"
                rel="noreferrer"
                className={buttonStyle.basic}
            >
                {text}
            </a>
        </div>
    );
};

export default GoToProductChangeRequestButton;

GoToProductChangeRequestButton.propTypes = {
    /**
     * Type of entity
     */
    entity: PropTypes.object.isRequired,
};
