import React from 'react';

// Components
import Form from '../../Framework/Components/Form/Form';

import TextArea from '../../Framework/Components/Form/TextArea';
import TextInput from '../../Framework/Components/Form/TextInput';
import Text from '../../Framework/Components/Text';
import TextDate from '../../Framework/Components/TextDate';
import TextResource from '../../Framework/Components/TextResource';
import Grid, { GridLeft, GridRight } from '../../Framework/Layouts/Grid';
import ReviewTransfer from '../../Framework/Pages/Product/ReviewTransfer';
import FormDelete from '../../Framework/Components/Buttons/FormDelete';
import ReviewReplies from './ReviewReplies';
import ChangeReviewTypeButton from './ChangeReviewTypeButton';

const ReviewForm = (props) => {
    return (
        <Form {...props}>
            <TextResource
                name="review_details"
                className="text-3xl mb-8"
                isParagraph
                {...props}
            />

            <Grid classNames="mb-16">
                <GridLeft>
                    <TextInput name="rate" />
                    <TextArea rows="10" name="description" fullFeatureSet />
                </GridLeft>
                <GridRight>
                    <div className="flex flex-col gap-4 text-2xl">
                        <Text
                            name="product.name"
                            includeLabel
                            isNested
                            customLabel="Product Name"
                        />
                        <Text name="id" includeLabel />
                        <Text name="flag_count" includeLabel />
                        <Text name="like_count" includeLabel />
                        <Text name="type" includeLabel />
                        <ChangeReviewTypeButton />
                        <TextDate name="created_at" includeLabel />
                        <TextDate name="updated_at" includeLabel />
                    </div>
                </GridRight>
            </Grid>

            <TextResource
                name="transfer"
                className="text-3xl mb-8"
                isParagraph
                {...props}
            />
            <ReviewTransfer {...props} />
            <FormDelete />
            <ReviewReplies commentResource="comments" {...props} />
        </Form>
    );
};

export default ReviewForm;
